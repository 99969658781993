module.exports = {
  title: "yoogle's records",
  description: "🐳",
  author: "yoogle",
  siteUrl: "https://www.yoogle.dev",
  links: {
    github: "https://github.com/yoogle96",
    // linkedIn: "https://linkedin.com",
    // facebook: "https://www.facebook.com",
    // instagram: "https://www.instagram.com",
    // email: "mailto:devhudi@gmail.com",
  },
  utterances: {
    repo: "yoogle96/blog-comments",
    type: "pathname",
  },
}
